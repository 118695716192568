* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}


img, video, iframe {
  max-width: 100%;
  height: auto;
}

img{
  user-select: none;
  pointer-events: none;
}
